<template>
  <div class="currencies-container">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol md="3" sm="12">
            <h1>{{$t("CURRENCY_LIST_PAGE_TITLE")}}</h1>
          </CCol>
          <CCol md="6" sm="12"></CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <SearchCurrencyHeader
          :onSearch="onSearch"
          :platformList="platformList"
          :statuses="statuses"
        />
        <CurrencyListTable
          :limit="limit"
          :activePage="activePage"
          :items="items"
          :total="total"
        />
        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { endpoints, env, Constants } from '@/constants';
import CurrencyListTable from '@/components/Currencies/CurrencyListTable';
import SearchCurrencyHeader from '@/components/Currencies/SearchCurrencyHeader';
import StakingPagination from '@/components/StakingPagination';
import stringHelper from '@/utils/string-helper';
import responseHelper from '@/utils/response-helper';

  export default {
    name: 'CurrencyList',
    components: {
      SearchCurrencyHeader,
      CurrencyListTable,
      StakingPagination
    },
    data() {
      return {
        items: [],
        platformList: [],
        statuses: [],
        filterData: {},
        total: 0,
        limit: env.app.pageSize,
        activePage: 1,
      };
    },
    computed: {
      offset() {
        return (this.activePage - 1) * this.limit;
      },
      pages() {
        return Math.ceil(this.total / this.limit);
      },
    },
    async mounted() {
      await Promise.all([this.getPlatforms(), this.getCurrencyStatuses()]);
      this.isLoading = false;
    },
    methods: {
      async getPlatforms() {
      try {
        const result = await this.$http.get(endpoints.getDropdownPlatforms);
        this.platformList = result.data.map(item => {
          return {
            value: item.platform,
            label: item.platform,
          };
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('CURRENCY_LIST_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
      async getCurrencyStatuses() {
        try {
          const result = await this.$http.get(endpoints.getDropdownCurrencyStatuses);
          this.statuses = stringHelper.translateTextForDropdownList(result.data, 'CURRENCY_STATUS');
        } catch (err) {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: this.$t('CURRENCY_LIST_PAGE_ERROR_TITLE'),
            text: responseHelper.getErrorMessage(err),
          });
        }
      },

      async getCurrencies(data) {
        data = data || {};
        this.filterData = {
          ...this.filterData,
          ...data,
        };
        const filterData = this.filterData;

        try {
          const params = {
            limit: this.limit,
            offset: this.offset,
            name: filterData.name,
            symbol: filterData.symbol,
            platform: filterData.platform,
            status: filterData.status,
            mobile_ios_status: filterData.mobile_ios_status,
            mobile_android_status: filterData.mobile_android_status,
          };
          const result = await this.$http.get(endpoints.getCurrencies, { params });

          this.items = result.data.items || [];
          this.total = result.data.total;
        } catch (err) {
          this.$notify({
            group: 'notifications',
            type: 'error',
            title: this.$t('CURRENCY_LIST_PAGE_ERROR_TITLE'),
            text: responseHelper.getErrorMessage(err),
          });
        }
      },
      async onChangePage(page) {
        this.activePage = page;

        this.getCurrencies();
      },

      async onChangePageSize(value) {
        this.limit = value;
        this.activePage = 1;

        this.getCurrencies();
      },

      async onSearch(searchData) {
        const data = searchData;
        this.activePage = 1;

        this.getCurrencies(data);
      },
    }
  };

</script>

<style lang="scss">
.currencies-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }
  }
}
</style>
