<template>
  <div class="search-currency-header-container">
    <ValidationObserver ref="form">
      <CRow>
        <CCol md="3" sm="12">
          <div role="group" class="form-group form-group">
            <label class>{{$t("SEARCH_CURRENCY_HEADER_COMPONENT_PLATFORM_LABEL")}}</label>
            <select v-model="searchData.platform" class="form-control">
              <option value>{{$t("SEARCH_CURRENCY_HEADER_COMPONENT_ALL_PLATFORMS_LABEL")}}</option>
              <option
                v-for="item in platformList"
                :key="item.value"
                :value="item.value"
              >{{item.label}}</option>
            </select>
          </div>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_CURRENCY_HEADER_COMPONENT_SYMBOL_FIELD_NAME"
            v-slot="{ errors }"
            rules="max:100"
          >
            <CInput
              :label="$t('SEARCH_CURRENCY_HEADER_COMPONENT_SYMBOL_LABEL')"
              type="text"
              v-model="searchData.symbol"
              class="form-group"
              @keyup.enter="search()"
            />
            <CRow>
              <CCol :md="12">
                <span class="error-msg-wrap">
                  <span class="error-msg">{{errors[0]}}</span>
                </span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_CURRENCY_HEADER_COMPONENT_NAME_FIELD_NAME"
            v-slot="{ errors }"
            rules="max:100"
          >
            <CInput
              :label="$t('SEARCH_CURRENCY_HEADER_COMPONENT_NAME_LABEL')"
              type="text"
              v-model="searchData.name"
              class="form-group"
              @keyup.enter="search()"
            />
            <CRow>
              <CCol :md="12">
                <span class="error-msg-wrap">
                  <span class="error-msg">{{errors[0]}}</span>
                </span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <div role="group" class="form-group form-group">
            <label class>{{$t("SEARCH_CURRENCY_HEADER_COMPONENT_STATUS_LABEL")}}</label>
            <select v-model="searchData.status" class="form-control">
              <option value>{{$t("SEARCH_CURRENCY_HEADER_COMPONENT_ALL_PLATFORMS_LABEL")}}</option>
              <option v-for="item in statuses" :key="item.value" :value="item.value">{{item.label}}</option>
            </select>
          </div>
        </CCol>

        <CCol md="3" sm="12">
          <div role="group" class="form-group form-group">
            <label class>{{$t("SEARCH_CURRENCY_HEADER_COMPONENT_MOBILE_IOS_STATUS_LABEL")}}</label>
            <select v-model="searchData.mobile_ios_status" class="form-control">
              <option value>{{$t("SEARCH_CURRENCY_HEADER_COMPONENT_ALL_PLATFORMS_LABEL")}}</option>
              <option v-for="item in statuses" :key="item.value" :value="item.value">{{item.label}}</option>
            </select>
          </div>
        </CCol>

        <CCol md="3" sm="12">
          <div role="group" class="form-group form-group">
            <label class>{{$t("SEARCH_CURRENCY_HEADER_COMPONENT_MOBILE_ANDROID_STATUS_LABEL")}}</label>
            <select v-model="searchData.mobile_android_status" class="form-control">
              <option value>{{$t("SEARCH_CURRENCY_HEADER_COMPONENT_ALL_PLATFORMS_LABEL")}}</option>
              <option v-for="item in statuses" :key="item.value" :value="item.value">{{item.label}}</option>
            </select>
          </div>
        </CCol>
      </CRow>
    </ValidationObserver>

    <CRow class="mt-2 form-actions">
      <div class="col-md-6 btn-disabled"></div>
      <CCol md="3" sm="12">
        <CButton
          class="btn btn-reset"
          @click="resetForm()"
        >{{$t("SEARCH_CURRENCY_HEADER_COMPONENT_RESET_BUTTON")}}</CButton>
      </CCol>

      <CCol md="3" sm="12">
        <CButton
          class="btn btn-search"
          @click="search()"
        >{{$t("SEARCH_CURRENCY_HEADER_COMPONENT_SEARCH_BUTTON")}}</CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import moment from 'moment';
import { Constants } from '@/constants';
import stringHelper from '@/utils/string-helper';
import sessionStorageHelper from '@/utils/sessionStorage-helper';
import dateTimeHelper from '@/utils/datetime-helper';

export default {
  name: 'SearchCurrencyHeader',
  components: {

  },
  data() {
    return {
      searchData: sessionStorageHelper.getData(sessionStorageHelper.KEYS.FilterCurrencies) || this.getDefaultValues(),
    };
  },
  props: {
    onSearch: {
      type: Function,
      default: null,
    },
    platformList: {
      type: Array,
      default: () => [],
    },
    statuses: {
      type: Array,
      default: () => [],
    },
  },
  async mounted() {
    this.search();
  },
  methods: {
    getDefaultValues() {
      return {
        platform: '',
        status: '',
        mobile_ios_status: '',
        mobile_android_status: '',
        name: '',
        symbol: '',
      };
    },
    resetForm() {
      this.searchData = this.getDefaultValues();

      this.search();
    },
    async search() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      sessionStorageHelper.setData(sessionStorageHelper.KEYS.FilterCurrencies, this.searchData);

      const searchData = {
        ...this.searchData,
      };
      console.log(searchData.mobile_ios_status,searchData.mobile_android_status)
      this.onSearch(searchData);
    },
  },
};
</script>>

<style lang="scss">
.search-currency-header-container {
  margin-bottom: 35px;
  .form-search {
    margin-bottom: 5px;
  }

  .form-actions {
    .btn {
      border: none;
      border-radius: 32px;
      height: 32px;
      padding: 0 30px;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      border: 1px solid #1a2958;
      border-radius: 17.5px;
      background-color: #1a2958;
      width: 100%;
      display: block;

      &.btn-search {
        background: #3b4b7e;
      }

      &.btn-reset,
      &.btn-download-csv {
        color: #1a2958;
        background-color: #fff;
      }
    }
  }
}
</style>
