<template>
  <div class="currency-table-container">
    <CCard class="currency-list-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template #platform="{item}">
          <td>
            {{item.platform | truncate(20)}}
          </td>
        </template>
        <template #symbol="{item}">
          <td>
            {{item.symbol | truncate(20)}}
          </td>
        </template>
        <template #name="{item}">
          <td>
            {{item.name | truncate(20)}}
          </td>
        </template>
        <template #type="{item}">
          <td>
            <StakingBadge type="currency-type" :text="item.type" />
          </td>
        </template>
        <template #icon="{item}">
          <td>
            <img v-if="item.icon" :src="item.icon" class="rounded currency-image" />
          </td>
        </template>

        <template #status="{item}">
          <td>
            <StakingBadge type="currency-status" :text="item.status" />
          </td>
        </template>
        <template #mobile_ios_status="{item}">
          <td>
            <StakingBadge type="currency-status" :text="item.mobile_ios_status" />
          </td>
        </template>
        <template #mobile_android_status="{item}">
          <td>
            <StakingBadge type="currency-status" :text="item.mobile_android_status" />
          </td>
        </template>

        <template #action="{item}">
          <td width="50px">
            <button class="btn btn-view-details" @click="viewDetails(item)">
              <img src="@/assets/images/ico-edit-user.svg" alt class="hand edit-icon" />
            </button>
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import StakingBadge from '@/components/StakingBadge';
import { Constants } from '@/constants';

export default {
  name: 'CurrencyListTable',
  components: {
    StakingBadge,
  },
  data() {
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        {
          key: 'platform',
          label: this.$t('CURRENCY_LIST_TABLE_COMPONENT_PLATFORM_COL'),
          _style: 'min-width: 80px; text-align: left;',
        },
        {
          key: 'symbol',
          label: this.$t('CURRENCY_LIST_TABLE_COMPONENT_SYMBOL_COL'),
          _style: 'min-width: 80px; text-align: left;',
        },
        {
          key: 'name',
          label: this.$t('CURRENCY_LIST_TABLE_COMPONENT_NAME_COL'),
        },
        {
          key: 'type',
          label: this.$t('CURRENCY_LIST_TABLE_COMPONENT_TYPE_COL'),
        },
        {
          key: 'icon',
          label: this.$t('CURRENCY_LIST_TABLE_COMPONENT_ICON_COL'),
        },
        {
          key: 'status',
          label: this.$t('CURRENCY_LIST_TABLE_COMPONENT_STATUS_COL'),
        },
        {
          key: 'mobile_ios_status',
          label: this.$t('CURRENCY_LIST_TABLE_COMPONENT_MOBILE_IOS_STATUS_COL'),
        },
        {
          key: 'mobile_android_status',
          label: this.$t('CURRENCY_LIST_TABLE_COMPONENT_MOBILE_ANDROID_STATUS_COL'),
        },
        {
          key: 'action',
          label: '',
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    viewDetails(item) {
      this.$router.push(`currencies/${item.id}/details`);
    },
  },
};
</script>

<style lang="scss">
.currency-table-container {
  .currency-list-table-card {
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }
      .btn {
        font-size: 14px;
        font-weight: 400;
        color: #0505a5;
        outline: none;
        padding-top: 0;
        padding-bottom: 0;
      }

      .currency-image {
        width: 30px;
      }

      .fixed-rate-icon {
        color: #5da30b;
      }
    }
  }
}
</style>

